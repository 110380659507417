<template>
  <div class="organizations">
    <div class="page_header">
      <h1>Юридические лица</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="organizations">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Организация</th>
            <th>Юридический адрес</th>
            <th>Фактический адрес</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(org, idx) in organizations" :key="org.id">
            <td>{{ ++idx }}</td>
            <td>{{ org.counterparty.name_short_with_opf }}</td>
            <td>
              <template v-if="org.address_legal">{{ org.address_legal.value }}</template>
            </td>
            <td>
              <template v-if="org.address_fact">{{ org.address_fact.value }}</template>
            </td>
            <td>
              <el-button
                  type="primary"
                  size="mini"
                  @click="openDrawer(org)"
                  icon="el-icon-edit"
              >Изменить
              </el-button>
              <el-popconfirm
                  @confirm="remove(org.id)"
                  confirm-button-text='Да'
                  cancel-button-text='Нет'
                  title="Вы действительно хотите удалить?">
                <el-button
                    slot="reference"
                    icon="el-icon-delete"
                    size="mini" type="danger"
                    style="margin-left: 5px;"></el-button>
              </el-popconfirm>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title font-ui">Юридическое лицо</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="formData" v-loading="submitting">
        <el-form :model="formData" :rules="formDataRules" ref="formData" class="form"
                 @submit.native.prevent="formDataAction('formData')">
          <el-form-item label="Наименование (для внутреннего использования)" prop="title">
            <el-input v-model="formData.title" size="mini"></el-input>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="8" :offset="0">
              <el-form-item label="ИНН" prop="counterparty.inn">
                <el-input v-model="formData.counterparty.inn" size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="0">
              <el-form-item label="КПП" prop="kpp">
                <el-input v-model="formData.counterparty.kpp" size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="0">
              <el-form-item label="ОГРН" prop="ogrn">
                <el-input v-model="formData.counterparty.ogrn" size="mini"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Наименование (автозаполнение)" prop="counterparty.name_short_with_opf">
            <el-autocomplete
                popper-class="ac_input_party"
                v-model="formData.counterparty.name_short_with_opf"
                :fetch-suggestions="partySearch"
                placeholder="Начните вводить наименование, инн или имя"
                size="mini"
                :clearable="true"
                :debounce="800 + 0"
                :trigger-on-focus="false"
                @select="handleSelectParty"
            >
              <template slot-scope="{ item }">
                <div class="value">{{ item.value }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>

          <el-form-item label="Форма собственности" prop="type">
            <el-select
                v-model="formData.counterparty.type"
                placeholder="Форма собственности"
                size="mini">
              <el-option
                  v-for="legalForm in legalForms"
                  :key="legalForm.value"
                  :label="legalForm.label"
                  :value="legalForm.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-row :gutter="10">
            <el-col :span="12" :offset="0">
              <el-form-item label="Должность руководителя" prop="management_post">
                <el-input v-model="formData.counterparty.management_post" size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="ФИО руководителя" prop="management_name">
                <el-input v-model="formData.counterparty.management_name" size="mini"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Юридический адрес" prop="address_legal">
            <el-autocomplete
                popper-class="ac_input_address"
                v-model="formData.address_legal.value"
                :fetch-suggestions="addressSearch"
                placeholder="Начните вводить адрес"
                size="mini"
                :clearable="true"
                :debounce="800 + 0"
                :trigger-on-focus="false"
                @select="handleSelectAddress"
            >
              <template slot-scope="{ item }">
                <div class="value">{{ item.value }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>

          <el-form-item label="Фактический адрес" prop="address_fact">
            <el-autocomplete
                popper-class="ac_input_address"
                v-model="formData.address_fact.value"
                :fetch-suggestions="factAddressSearch"
                placeholder="Начните вводить адрес"
                size="mini"
                :clearable="true"
                :debounce="800 + 0"
                :trigger-on-focus="false"
                @select="handleSelectFactAddress"
            >
              <template slot-scope="{ item }">
                <div class="value">{{ item.value }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>


          <el-form-item label="Комментарий" prop="comment">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                placeholder="Комментарий"
                v-model="formData.comment"
                size="mini"></el-input>
          </el-form-item>


          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="formDataAction('formData')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import {mapState} from 'vuex'
import {actionTypes as organizationActions} from "@/store/modules/organizations"

import autocompleteApi from '@/api/autocomplete'

export default {
  name: "Organizations",
  data() {
    return {
      drawerActive: false,
      formData: null,
      formDataRules: {
        'title': [
          {required: true, message: 'Введите наименование', trigger: 'blur'}
        ],
        'counterparty.inn': [
          {required: true, message: 'Введите ИНН', trigger: 'blur'}
        ],
        'counterparty.name_short_with_opf': [
          {required: true, message: 'Введите наименование', trigger: 'blur'}
        ]
      },
      legalForms: [
        {label: 'Индивидуальный предприниматель', value: 'individual'},
        {label: 'Общество с ограниченной ответственностью', value: 'legal'},
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.organizations.loading,
      submitting: state => state.organizations.submitting,
      organizations: state => state.organizations.list,
      errors: state => state.organizations.error
    })
  },
  methods: {
    create() {
      this.formData = {
        title: '',
        counterparty: {
          value: '',
          unrestricted_value: '',
          inn: '',
          kpp: '',
          ogrn: '',
          type: '',
          name_full_with_opf: '',
          name_short_with_opf: '',
          management_post: '',
          management_name: '',
        },
        comment: '',
        address_fact: {
          value: null
        },
        address_legal: {
          value: null
        }
      };
      this.drawerActive = true;
    },
    openDrawer(org) {
      this.drawerActive = true
      this.formData = _.cloneDeep(org)
    },
    closeDrawer() {
      this.formData = null
      this.drawerActive = false
    },
    formDataAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(organizationActions.save, this.formData).then(() => {
            this.loadOrganizations()
            this.drawerActive = false
            this.formData = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    addressSearch(queryString, cb) {
      if (queryString.length > 5) {
        autocompleteApi.searchAddress({query: queryString}).then((result) => {
          if (result.data.length) {
            cb(result.data);
          } else {
            cb([]);
          }
        });
      }
    },
    handleSelectAddress(item) {
      this.formData.address_legal = item;
    },
    factAddressSearch(queryString, cb) {
      if (queryString.length > 5) {
        autocompleteApi.searchAddress({query: queryString}).then((result) => {
          if (result.data.length) {
            cb(result.data);
          } else {
            cb([]);
          }
        });
      }
    }
    ,
    handleSelectFactAddress(item) {
      this.formData.address_fact = item;
    },
    partySearch(queryString, cb) {
      if (queryString.length > 4) {
        autocompleteApi.searchParty({query: queryString}).then((result) => {
          if (result.data.length) {
            cb(result.data);
          } else {
            cb([]);
          }
        });
      }
    },
    handleSelectParty(item) {
      console.log(item)
      this.formData.counterparty.dadata = item

      this.formData.title = item.data.name.short_with_opf
      this.formData.counterparty.type = item.data.type.toLowerCase()
      this.formData.counterparty.name_short_with_opf = item.data.name.short_with_opf
      this.formData.counterparty.inn = item.data.inn
      this.formData.counterparty.kpp = item.data.kpp
      this.formData.counterparty.ogrn = item.data.ogrn
      if (item.data.management != undefined) {
        this.formData.counterparty.management_post = item.data.management.post.toLowerCase();
        this.formData.counterparty.management_name = item.data.management.name;
      }
      if (this.formData.counterparty.type == "individual") {
        this.formData.counterparty.management_post = "Индивидуальный предприниматель";
        this.formData.counterparty.management_name = item.data.name.full;
      }

      this.formData.address_legal = _.cloneDeep(item.data.address)
      this.formData.address_fact = _.cloneDeep(item.data.address)
    },
    remove(id){
      this.$store.dispatch(organizationActions.remove, id).then(() => {
        this.loadOrganizations()
      })
    },
    loadOrganizations() {
      this.$store.dispatch(organizationActions.get)
    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
